import store from "../store";

import { API_HOST, WEB_HEADERS } from "@/core/config";
import { getLoginMode, getLoginUser } from "@/core/utils/session";
import { ALERT_ERROR } from "@/domain/constant/store/actions/alert-actions";
import {
  MOVE_CONVERSATION_TO_TOP,
  READ_CONVERSATION_MESSAGE,
  REMOVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION,
  SET_CONVERSATION,
  SET_CONVERSATION_CHAT_STATUS,
  UPDATE_CONVERSATION,
  UPDATE_GROUP_CONVERSATION
} from "@/domain/constant/store/actions/conversation-actions";
import {
  RECEIVE_NEW_MESSAGE,
  UPDATE_SENT_MESSAGE,
  UPDATE_TOTAL_UNREAD_COUNT
} from "@/domain/constant/store/actions/message-actions";
import { SET_FRIEND_CHAT_STATUS } from "@/domain/constant/store/actions/user-actions";
import { USER_MODE } from "@/domain/constant/user-constant";
import { ILastMessage, IMessage } from "@/domain/interfaces/message-interfaces";
import { Socket, io } from "socket.io-client";
import { ClientToServerEvents, ServerToClientEvents } from "./interface";

let socket: Socket<ServerToClientEvents, ClientToServerEvents>;
interface ISocketConnect {
  userId: string;
  mode: string;
  takeCareGroup: string;
}
const getSocket = (data?: ISocketConnect) => {
  const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
    API_HOST,
    {
      auth: {
        product: WEB_HEADERS.product,
        token: WEB_HEADERS.token,
        org: "",
        user_id: data?.userId || getLoginUser()?.id,
        mode: data?.mode || getLoginMode(),
        take_care_group:
          data?.takeCareGroup || getLoginUser()?.takeCareGroup?.id,
      },
      reconnectionDelayMax: 20000,
      query: {},
      withCredentials: true,
      reconnectionDelay: 2000,
      transports: ["polling", "websocket"],
    }
  );

  return socket;
};

export const ACTION = {
  emit: "emit",
  listen: "listen",
};

export const emitStartChat = (data: {
  users: Array<string>;
  name?: string;
  avatar?: string;
  description?: string;
}) => {
  socket.emit("conversation:startChat", data);
};

export const emitActiveConversation = (data: {
  conversation: string;
  user: string;
  forwardMessage?: IMessage;
}) => {
  socket.emit("conversation:activeConversation", data, () => null);
};

export const emitAddGroupUser = (data: {
  users: Array<string>;
  conversation: string;
}) => {
  socket.emit("conversation:addUserToGroup", data);
};

export const emitRemoveGroupUser = (data: {
  user: string;
  conversation: string;
}) => {
  socket.emit("conversation:removeUserFromGroup", data);
};

export const emitSendMessage = (
  mode: string,
  payload: {
    content: string;
    type: string;
    senderSentAt: Date;
    forward?: string;
  }
) => {
  const { activeConversation } = store.state.conversation;
  const { loginUser } = store.state.user;

  if (!activeConversation || !loginUser) return;

  if (mode === USER_MODE.customerCare)
    socket.emit("customerCare:sendMessage", {
      conversation: activeConversation._id,
      sender: loginUser.id,
      takeCareGroup: activeConversation.takeCareGroup,
      receiver: activeConversation.receiveUser.id,
      content: payload.content,
      type: payload.type,
      senderSentAt: payload.senderSentAt,
      forward: payload.forward,
    });
  else if (mode === USER_MODE.customer)
    socket.emit("customer:sendMessage", {
      conversation: activeConversation._id,
      sender: loginUser.id,
      receiver: activeConversation.receiveUser.id,
      content: payload.content,
      type: payload.type,
      senderSentAt: payload.senderSentAt,
      forward: payload.forward,
    });
  else if (mode === USER_MODE.user)
    socket.emit("user:sendMessage", {
      conversation: activeConversation._id,
      sender: loginUser.id,
      receiver: activeConversation.receiveUser.id,
      content: payload.content,
      type: payload.type,
      senderSentAt: payload.senderSentAt,
      forward: payload.forward,
      isBot: activeConversation.receiveUser.isBot,
    });
};

export const emitReadMessage = (data: {
  conversationId: string;
  detailId: string;
  messageId: string;
}) => {
  socket.emit("conversation:readMessage", data);
};

export const emitUpdateGroupInfo = (data: {
  conversation: string;
  name?: string;
  avatar?: string;
  description?: string;
}) => {
  socket.emit("conversation:updateGroupInfo", data);
};

const handleDebug = (event: string, data: any) => {
  const debug = process.env.NODE_ENV !== "production";

  if (debug)
    console.log("\x1b[32m%s", event, `\n\x1b${JSON.stringify(data)}\x1b`);
};

const handleSocketConnect = () => {
  console.log("Kết nối thành công!");
};

export default function socketConnect (data?: ISocketConnect) {
  socket = getSocket(data);
  socket.on("connect_error", (error) => {
    handleDebug("connect_error", error);
  });

  socket.on("disconnect", (error) => {
    handleDebug("disconnect", error);
  });

  socket.on("connect", handleSocketConnect);

  socket.on("error", (error) => {
    store.dispatch(ALERT_ERROR, error.message);
  });

  socket.on("newMessage", ({ message, unreadCount, conversation }) => {
    //  có tin nhắn mới
    handleDebug("newMessage", { message, unreadCount });
    store.dispatch(MOVE_CONVERSATION_TO_TOP, conversation);
    const { activeConversation } = store.state.conversation;
    const messageInActiveConversation =
      activeConversation?._id === message.conversation;

    if (messageInActiveConversation)
      setTimeout(() => {
        store.dispatch(READ_CONVERSATION_MESSAGE, {
          conversationId: message.conversation,
          messageId: message._id,
        });
      }, 1000);

    store.dispatch(UPDATE_CONVERSATION, {
      conversation: message.conversation,
      lastMessage: <ILastMessage>{
        _id: message._id,
        content: message.content,
        type: message.type,
        sentAt: message.sentAt,
      },
      unreadCount: messageInActiveConversation ? 0 : unreadCount,
    });
    store.dispatch(RECEIVE_NEW_MESSAGE, message);
    store.dispatch(
      UPDATE_TOTAL_UNREAD_COUNT,
      store.state.message.totalUnreadCount + 1
    );
  });

  socket.on("sentMessage", ({ message, conversation }) => {
    //  tin nhắn đã gửi thành công
    handleDebug("sentMessage", { message });
    store.dispatch(MOVE_CONVERSATION_TO_TOP, conversation);
    store.dispatch(UPDATE_SENT_MESSAGE, message);
    store.dispatch(UPDATE_CONVERSATION, {
      conversation: message.conversation,
      lastMessage: <ILastMessage>{
        _id: message._id,
        content: message.content,
        type: message.type,
        sentAt: message.sentAt,
      },
    });
  });

  socket.on("totalUnreadCount", (count) => {
    handleDebug("totalUnreadCount", { count });
    store.dispatch(UPDATE_TOTAL_UNREAD_COUNT, count);
  });

  socket.on("activeConversation", (data) => {
    //active conversation
    handleDebug("activeConversation", data);
    store.dispatch(SET_ACTIVE_CONVERSATION, {
      conversation: data.conversation,
      forwardMessage: data.forwardMessage,
    });
  });

  socket.on("setConversation", ({ conversation }) => {
    //active conversation
    handleDebug("setConversation", { conversation });
    store.dispatch(SET_CONVERSATION, conversation);
  });

  socket.on("joinNewConversation", ({ conversation }) => {
    //joinNewConversation
    handleDebug("joinNewConversation", { conversation });
    store.dispatch(MOVE_CONVERSATION_TO_TOP, conversation);
  });

  socket.on("chatStatus", (data) => {
    //get user online or offile
    handleDebug("chatStatus", { data });
    store.dispatch(SET_FRIEND_CHAT_STATUS, data);
    store.dispatch(SET_CONVERSATION_CHAT_STATUS, data);
  });

  socket.on("removeConversationUser", (data) => {
    handleDebug("removeConversationUser", { data });
    store.dispatch(REMOVE_CONVERSATION, data.conversation);
  });

  socket.on("updateGroupInfo", (data) => {
    handleDebug("updateGroupInfo", { data });
    store.dispatch(UPDATE_GROUP_CONVERSATION, {
      ...data,
      action: ACTION.listen,
    });
  });
}
